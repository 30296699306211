
import { computed, defineComponent, ref } from 'vue';

import { applyForm, createTokenByGoogleIdToken, googleProfile } from '@/composable/auth';
import router, { RouteRecord } from '@/router';
import { ApplyFormStatusEnum } from '@/view-models/auth.vm';
import { formatDate } from '@/utils/formatter';

export default defineComponent({
  setup() {
    const isPending = computed(() => ApplyFormStatusEnum.pending === applyForm.value.status);
    const isDeny = computed(() => ApplyFormStatusEnum.deny === applyForm.value.status);
    const gender = computed(() => {
      switch (applyForm.value.gender) {
        case 'male':
          return '男';
        case 'female':
          return '女';
        default:
          return applyForm.value.gender;
      }
    });

    function goForm() {
      router.push({ name: RouteRecord.SignUpForm.name });
    }

    createTokenByGoogleIdToken();

    return {
      googleProfile,
      applyForm,
      gender,
      isDeny,
      isPending,

      goForm,
      formatDate,
    };
  },
});
